<template>
  <div>
   <!-- <div class="topheader">
      <div class="searchInput">
        <span>我发布的团</span>
        <el-button type="primary" @click="addfun">一键开团</el-button>
      </div>
      <passWord />
    </div> -->
    <div class="contents">
     <div class="tableSearch">
         <div class="biaoticss"><strong>我发布了 {{total}} 个团</strong> <span><el-button size="medium" icon="el-icon-circle-plus-outline" type="primary" @click="addfun">一键开团</el-button></span></div> 
          <el-form :inline="true" ref="searchForm" :model="searchForm" label-width="70px">
           <el-form-item style="margin-bottom:0px">
            <el-date-picker v-model="searchForm.date" type="daterange" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
        </el-form-item>
             <el-form-item label="状态" style="margin-bottom:0px">
              <el-select v-model="searchForm.status" clearable  placeholder="全部" style="width:120px">
                <el-option label="进行中" value="1"></el-option>
                <el-option label="已结束" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="供应商" style="margin-bottom:0px">
               <el-input v-model="searchForm.supplierName" clearable placeholder="供应商名称"></el-input>
            </el-form-item>
            <el-form-item label="" style="margin-bottom:0px">
              <el-input v-model="searchForm.name" clearable placeholder="团标题" style="width:220px"></el-input>
            </el-form-item>
            <el-button @click="getTopSearch" type="primary" icon="el-icon-search">查询</el-button>
            <el-button @click="chongzhi">重置</el-button>
          </el-form>
        </div>
     <div class="cardList">
     <div class="cardBox" v-for="item in tableData" v:key="item.id">
        <div class="cardItemol">
        <div class="biaoti" :title="item.name">
          <template v-if="item.cities.length > 0">
          <template v-for="its in item.cityArr" v:key="its.city">
            <bdo v-if="its.city">{{its.city}}</bdo>            
            </template>
          </template>  
            <bdo v-else>全国</bdo>
          {{item.name}}
        </div>
        <div class="pricebox">
           <span>{{item.beginTimes}}至{{item.endTimes}}</span><em>￥</em>{{item.minPrice?item.minPrice:0}}
        </div>
        <div class="imglist">
          <template v-for="(it, indexs) in item.images" v:key="indexs">
            <ol v-if="indexs<3"><img :src="it" class="imgsize"/></ol>
          </template>
        </div>
        <div class="clickNums">
            <ol>
              <strong>{{item.browseTotal?item.browseTotal:0}}</strong>
              <p>浏览数</p>
            </ol>
            <ol>
              <strong>{{item.favoriteTotal?item.favoriteTotal:0}}</strong>
              <p>分销数</p>
            </ol>
            <ol>
              <strong>{{item.orderTotal?item.orderTotal:0}}</strong>
              <p>订单数</p>
            </ol>
            <ol>
              <p style="margin-top:10px; text-align:right;cursor: pointer;" @click="chakantuan(item)">团购详情<i class="iconfont icon-iconfontjiantou2"></i></p>
            </ol>
          </div>
        <div class="caozuoBtn">
          <bdo v-if="item.status ==1">{{item.statusDescription}}</bdo>
          <bdo v-else class="huise">{{item.statusDescription}}</bdo>
            <span><el-button v-if="item.status == 1" @click.native.prevent="updownFun(item.id, 0)">下架</el-button></span>
            <span><el-button v-if="item.status == 0" @click.native.prevent="updownFun(item.id, 1)">上架</el-button></span>
            <span><el-button @click.native.prevent="editFun(item.id)">编辑</el-button></span>
            <!-- <span><el-button v-if="!item.favorite" @click.native.prevent="choucang(item.id)">分销</el-button></span> -->
            <span><el-button type="primary" plain @click.native.prevent="uploadFun(item)">查看订单</el-button></span>
        </div>
        </div>
      </div>
     </div>
   <div class="paginations">
        <el-pagination
          background
          layout="total, prev, pager, next, sizes"
          @current-change="changePage" :current-page="page" :page-size="pageSize"
          :page-sizes="[20, 40, 50, 100]"
          :total="total">
        </el-pagination>
      </div>
    </div>

  


     <el-dialog title="我要分销" :visible.sync="downflag" :before-close="downClose" width="600px">
        <div class="fenxiaoBox">
          <ol>
          <img src="../../assets/ma.jpg" class="fenimg"/>
          <p>快团团</p>
          <el-button @click.native.prevent="downloadFun">下载海报</el-button>
          </ol>
        </div>
    </el-dialog>

    <el-dialog title="订单解析结果" :visible.sync="orderflag" :before-close="ooClose" width="1000px" style="margin-top:5%">
      <div class="orderHeight">
      <el-table
        :data="orderData"
        border
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        style="width: 100%">
        <el-table-column
          prop="onlineOrderId"
          label="订单号">
        </el-table-column>
        <el-table-column
          prop="goodsName"
          label="商品">
        </el-table-column>
        <el-table-column
          prop="specName"
          label="规格">
        </el-table-column>
        <el-table-column
          prop="num"
          label="数量">
        </el-table-column>
        <el-table-column
          prop="consigneeName"
          label="收货人" width="100px"> 
        </el-table-column>
        <el-table-column
          prop="consigneePhone"
          label="电话" width="120px"> 
        </el-table-column>
        <el-table-column
          prop="consigneeAddress"
          label="收货地址">
        </el-table-column>
         <!-- <el-table-column
          label="操作"
          width="100" header-align="center" align="center">
      <template slot-scope="scope">
        <el-button type="text" size="small" @click="modifyFun(scope.row)">修改</el-button>
      </template>
    </el-table-column> -->
      </el-table>
      </div>
        <div class="midCss" style="padding-top:20px">
          <el-button type="primary" @click="handleSubmit">确认保存</el-button>
          <el-button @click="handleClose">取 消</el-button>
        </div>
    </el-dialog>

    <el-dialog title="订单修改" :visible.sync="modyflag" :before-close="modyClose" width="600px">
      <el-form ref="formAdd" :model="formAdd" label-position="right">
       <el-form-item label="商品名称：">
            <el-input v-model="formAdd.goodsName" placeholder="请输入商品名称"></el-input>
          </el-form-item>
          <el-form-item label="收货人：">
            <el-input v-model="formAdd.consigneeName" placeholder="请输入收货人"></el-input>
          </el-form-item>
          <el-form-item label="联系电话：">
            <el-input v-model="formAdd.consigneePhone" placeholder="请输入联系电话"></el-input>
          </el-form-item>
          <el-form-item label="联系地址：">
            <el-input v-model="formAdd.consigneeAddress" placeholder="请输入联系地址"></el-input>
          </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { publishTuan, changeTuan } from "@/api/kaituan";
import { importOrder, batchAdd } from "@/api/orders";
import passWord from "@/components/password"
let moment = require('moment');
export default {
  name: 'Main',
  components: { passWord },
  data() {
    return {
      searchForm: {
        date: '',
        endTime: '', 
        startTime: '', 
        goods: '',
        name: '',
        status: '1',
        supplierOrgId: ''
      },
      page: 1,
      total: 0,
      pageSize: 20,
      passVisible: false,
      uploadflag: false,
      fileList: [],
      tableData: [],
      orderData: [],
      orderflag: false,
      logining: false,
      modyflag: false,
      downflag: false,
      formAdd: {
        goodsName: '',
        consigneeName: '',
        consigneePhone: '',
        consigneeAddress: ''
      }
    }
  },
  methods: {
    choucang(){
      this.downflag = true
    },
    getTopSearch(){
      var that = this
      var datas = that.searchForm;
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.orderByCreated = "DESC";
      datas.start = that.searchForm.date ? moment(that.searchForm.date[0]).format('YYYY-MM-DD') : '';
      datas.end = that.searchForm.date ? moment(that.searchForm.date[1]).format('YYYY-MM-DD') : '';
      publishTuan(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
           var nodataArr = res.data.rows || []
          nodataArr.map((item)=>{
            item.beginTimes = moment(item.startTime).format('YYYY-MM-DD')
            item.endTimes = moment(item.endTime).format('YYYY-MM-DD')
            item.cityArr = that.uniqueFun(item.cities, 'city')
          })
          that.tableData = nodataArr
          that.total = res.data.totalRows
        }else{
          this.$message.error(res.message)
        }
      })
    },
    chakantuan(row){
      this.$router.push({
        path: "/tuandetail?id=" + row.id
      })
    },
    uploadFun(row){
      this.$router.push({
        path: "/supdingdan?id=" + row.id + '&tuanname=' + row.name
      })
    },
           //根据对象某个属性去重
    uniqueFun(arr, val){
      const res = new Map()
      return arr.filter(item =>!res.has(item[val]) && res.set(item[val], 1))
    },
    updownFun(id, n){
      var that = this
      var objs = {
        id: id,
        status: n
      }
      var txts = n===1?'确定上架该团购？':'确定下架该团购？'
      this.$confirm(txts, '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        changeTuan(objs).then(res => {
          if(res.code === 200){
            that.$message({
              message: '操作成功',
              type: 'success'
            })
            that.getTopSearch()
          }else{
            that.$message.error(res.message)
          }
        })
      })
    },
    ooClose(){
      this.orderflag = false
    },
    modyClose(){
      this.modyflag = false
    },
    handleClose(){
      this.uploadflag = false
      this.orderflag = false
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
    handleSubmit(){
      var that = this
      var objs = {}
      objs.orders = that.orderData
      batchAdd(objs).then(res => {
          this.$router.push({
            path: "/dingdan"
          })
      })
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    editFun(row){
      this.$router.push({
        path: "/sendtuan/detail/" + row
      })
    },
    handlePreview(file) {
      console.log(file)
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${ file.name }？`)
    },
    addfun(){
      this.$router.push({
        path: "/sendtuan/detail"
      })
    }, 
    modifyFun(row){
      this.modyflag = true
      this.formAdd = row
    },
    downClose(){
      this.downflag = false
    },
    chongzhi(){
      this.searchForm = {
        date: '',
        endTime: '', 
        startTime: '', 
        goods: '',
        name: '',
        status: '1',
        supplierOrgId: '',
      }
      this.page = 1
      this.getTopSearch()
    }
  },
  mounted() {
    console.log(this.$route.query.id)
    var ids = this.$route.query.id
    if(ids){
      this.searchForm.supplierOrgId = ids
    }
    this.getTopSearch()
  }
}
</script>

<style scoped>
.searchInput span{
  padding-right: 40px;
}

.el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
}
.orderHeight{
  height: 500px;
  overflow-x: hidden;
  overflow-y:scroll;
  -webkit-overflow-scrolling: touch;
}
.cardList{
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: block;
  margin: 0 auto;
  overflow: hidden;
}

@media screen and (max-width: 1338px){
  .cardList{
    width: 844px;
  }
}
@media screen and (min-width: 1338px){
  .cardList{
    width: 1266px;
  }
}
@media screen and (min-width: 1920px){
  .cardList{
    width: 1688px;
  }
}
.cardBox{
  float: left;
  width: 420px;
  overflow: hidden;
}
.cardItemol{
  margin: 5px;
  display: block;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 0 10px #eee;
  border-radius: 4px;
}

.biaoti{
  font-size: 14px;
  color: #333;
  height: 50px;
  overflow: hidden;
  line-height: 22px;
  display: block;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.biaoti span{
  background-color: #ff6600;
  color: #fff;
  padding: 2px 10px;
}
.biaoti bdo{
  /* background-color: #fe4729; */
  padding: 0 6px;
  margin-right: 4px;
  display: inline-block;
  color: #fe4729;
  border-radius:4px;
  font-size:12px;
  border: 1px solid #fe4729;
  margin-bottom: 2px;
}
.pricebox{
  display: block;
  line-height: 30px;
  color: #ff6600;
  font-weight: bold;
  font-size: 20px;
  height: 30px;
  padding: 5px 0;
}
.pricebox em{
  font-style: normal;
  font-size: 12px;
}
.pricebox span{
  float: right;
  font-size: 12px;
  color: #999;
  font-weight: normal;
}
.imglist{
  display: block;
  overflow: hidden;
  padding-bottom: 10px;
  height: 120px;
}
.imglist ol{
  float:left;
  width: 33.33%;
  margin: 0;
  padding: 0;
}
.imgsize{
  width: 110px;
  height: 110px;
  display: block;
  background-color: #fff;
  border-radius: 5px;
  margin: 0 auto;
}
.caozuoBtn{
  padding: 10px 0 0 0;
  overflow: hidden;
  font-size: 12px;
}
.caozuoBtn bdo{
  color:#07c160;
  line-height:30px;
  padding: 4px 12px;
  border-radius: 20px;
  background: rgba(7,193,96,.08);
}
.caozuoBtn bdo.huise{
  background: rgba(21,21,22,.08);
  color: #9c9c9c;
}
.upload-demo{
  display: block;
  margin: 0 auto;
  padding: 10px 0 50px;
  text-align: center;
}
.caozuoBtn span{
  float: right;
  margin-left: 10px;
  /* border: 1px solid #999;
  padding: 2px 10px;
  line-height:22px;
  border-radius: 4px;
  color:#282828;
  margin-left: 10px;
  cursor: pointer; */
}
.biaoticss{
  padding-bottom: 15px;
  font-size: 18px;
}
.biaoticss span{
  float:none;
  margin-top: -10px;
}
.fenxiaoBox{
  display: block;
  overflow: hidden;
}
.fenxiaoBox ol{
  float: left;
  width: 30%;
  text-align:center;
  border: 1px solid #eee;
  padding: 10px;
}
.fenimg{
  width: 160px;
  display: block;
  margin: 0 auto;
}
.clickNums{
  background-color: #ebfef4;
  padding: 10px;
  display: block;
  overflow: hidden;
}
.clickNums ol{
  float:left;
  width: 25%;
  text-align: center;
  font-size: 12px;
  color: #999;
}
.clickNums ol strong{
  font-size: 14px;
  display: block;
  font-weight: normal;
  margin-bottom: 5px;
  color:#07C160;
}
</style>

