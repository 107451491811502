<template>
  <div class="password">
    <div class="adminInfo">
      <span style="padding:0"><bdo style="margin:0"><i class="iconfont icon-huo"></i>{{userInfo.validity && userInfo.validity.status}}</bdo></span>
      <span class="adminbg"><bdo style="background: #fdeeeb; padding-left: 5px;"><i class="iconfont icon-VIP1"></i>VIP</bdo>
     
       <el-dropdown @command="handleOrgUser">
       <el-button type="text" style="padding: 0 10px; color: #999">
          <bdo class="iconName">{{userInfo.loginEmpInfo?userInfo.loginEmpInfo.orgName:''}}({{userInfo.phone}})</bdo> 切换账号<i class="el-icon-arrow-down el-icon--right"></i>
       </el-button>
        <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item v-for="(item,index) in tenants" :key="index" :command="item.id">{{item.name}}</el-dropdown-item>
        </el-dropdown-menu>
      </template>
     </el-dropdown>

      <el-dropdown @command="handleCommand">
       <el-button>
          {{adminName}}<i class="el-icon-arrow-down el-icon--right"></i>
       </el-button>
        <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item icon="el-icon-user" command="4">当前账号：{{userInfo.account}}
            昵称：{{userInfo.nickName}}
          </el-dropdown-item>
          <el-dropdown-item icon="el-icon-open" command="1">切换身份</el-dropdown-item>
          <el-dropdown-item icon="el-icon-lock" command="2">修改密码</el-dropdown-item>
          <el-dropdown-item icon="el-icon-switch-button" command="3">退出系统</el-dropdown-item>
        </el-dropdown-menu>
      </template>
     </el-dropdown>
     </span>
      <!-- <i class="el-icon-s-custom"></i>{{userInfo.name}}({{userInfo.phone}})
      <span @click="modify"><i class="el-icon-lock"></i> 修改密码</span>
      <span @click="logout"><i class="el-icon-switch-button"></i> 退出</span> -->
    </div>
    
    <el-dialog title="修改密码" :visible.sync="qunVisible" :before-close="handleClose" width="600px" style="z-index:9999">
      <el-form ref="formAdd" :model="formAdd" :rules="ruleShang" label-position="right" label-width="120px">
        <el-form-item label="新密码" prop="pass1">
          <el-input v-model="formAdd.pass1" autocomplete="off" show-password placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="pass2">
          <el-input v-model="formAdd.pass2" autocomplete="off" show-password placeholder="请再输入一次密码"></el-input>
        </el-form-item>
        <div class="midCss">
          <el-button type="primary" :loading="logining" @click="handleSubmit('formAdd')" size="medium">确认修改</el-button>
          <el-button @click="handleClose" size="medium">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>

     <el-dialog title="将您的身份切换为" :visible.sync="adminVisible" :before-close="handleClose" width="680px">
       <div class="qiehuan">
         <ol @click="checkAdmin('supplier')"><span class="quanquan"><i class="el-icon-user-solid"></i></span>供应商端</ol>
         <ol @click="checkAdmin('retailer')"><span class="quanquan quanyellow"><i class="el-icon-user"></i></span>团长端</ol>
         <ol @click="checkAdmin('wms')"><span class="quanquan quanblue"><i class="el-icon-school"></i></span>仓库端</ol>
       </div>
    </el-dialog>
  </div>
</template>

<script>
import { modiflyPassWord, logout,  getUserInfo, changeOrganization} from '@/api/login'
export default {
  name: 'addMerchant',
  props: ['showlayer'],
  data () {
    return {
      formAdd: {
        pass1: '',
        pass2: ''
      },
      passVisible: false,
      qunVisible: false,
      adminVisible: false,
      ruleShang: {
        pass1: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        pass2: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { validator: this.validatePass2, trigger: 'blur' }
        ]
      },
      userInfo: {
        name: 'admin',
        phone: ''
      },
      adminName: '',
      tenants: [],
      logining: false
    }
  },
  created: function () { },
  watch: {
    formdata: {
      deep: true,
      handler (newVal, oldVal) {
        console.log(oldVal)
        this.formAdd = newVal
      }
    }
  },
  methods: {
    handleClose () {
      this.qunVisible = false
      this.adminVisible = false
    },
    handleCommand(command) {
      if(command==1){
        this.qiehuan()
        return
      }
      if(command=="2"){
        this.modify();
        return
      }
      if(command=="3"){
        this.logout()
        return
      }
      if(command=="4"){
       
        return
      }
    },
    modify(){
      this.qunVisible = true
    },
    qiehuan(){
      this.adminVisible = true
    },
    checkAdmin(val){
      var that = this
      window.sessionStorage.setItem('role', val)
      that.adminName = (val==='retailer'?'团长端':(val==='wms'?'仓库端':'供应商端'))
      that.$emit('adminrole', val)
      that.adminVisible = false
       console.log(that.$router +'当前路由2121212')
      var paths = that.$router.history._startLocation
      console.log(paths +'当前路由：：：：')
      if(paths.indexOf('dashboard')==-1){
        that.$router.push({
          path: `/dashboard`
        })
      }
    },
    getUserInfos(){
      var that = this
      getUserInfo().then(res => {
        console.log(res);
        if(res.code === 200){
          if(res.data.validity.expired) {
            this.$alert("您的试用已到期，请续费后继续使用", "温馨提示", {showClose:false, showConfirmButton:false, showCancelButton:false})
            return
          }
          that.userInfo = res.data
          that.tenants = res.data.tenants
          var val = '';
          if(window.sessionStorage.getItem('role')){
            val = window.sessionStorage.getItem('role')
          }else{
            val = res.data.role.pc
            window.sessionStorage.setItem('role', val)
          }
          console.log(val)
          that.adminName = (val==='retailer'?'团长端':(val==='wms'?'仓库端':'供应商端'))
        
          that.$emit('adminrole', val)
        } else {
          this.$message.error(res.message)
          return
        }
      })
     
    },
    handleOrgUser(command){
      var that = this
      var objs = {
        id: command
      } 
      changeOrganization(objs).then(res => {
        console.log(res);
        if(res.code === 200){
         // that.getUserInfos()
         window.sessionStorage.setItem('accessToken', res.data)
         that.$router.go(0)
        } else {
          this.$message.error(res.message)
          return
        }
      })
    },
    logout () {
      this.$confirm('确定要退出系统吗?', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
         logout().then(res => {
           window.sessionStorage.removeItem('accessToken')
           window.sessionStorage.removeItem('role')
           this.$router.push({
              path: `/`
          })
         })
      }).catch()
    },
    validatePass2 (rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入新密码'))
      } else if (value !== this.formAdd.pass1) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    },
    handleSubmit (formName) {
      let that = this
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.logining = true
          let datas = {
            'newPassword': that.formAdd.pass2
          }
          modiflyPassWord(datas).then(res => {
            that.logining = false
            if (parseInt(res.code) === 200) {
                that.$message({
                  message: '重置密码成功',
                  type: 'success'
                });
                setTimeout(() => {
                  that.qunVisible = false
                }, 500)
             
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    }
  },
  mounted() {
    this.getUserInfos()
  },
}
</script>

<style scoped>
.el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
}
.midCss {
  text-align: left;
  display: block;
  padding-left: 120px;
  padding-top: 20px;
}
.tips {
  font-size: 12px;
  color: #999;
  padding-left: 120px;
}
.adminInfo {
  float: right;
  line-height: 30px;
  padding: 12px;
  font-size: 15px;
  color: #535353;
}
.adminInfo bdo{
  font-size: 16px;
  color: #fe4729;
  font-weight: bold;
  background-color: #fdeeeb;
  display: inline-block;
  padding: 0 8px;
}
.adminInfo bdo.iconName{
  background:none;
  padding: 0;
  color: #535353;
  font-size: 13px;
}

.adminInfo .icon-VIP1{
  float: left;
  font-size: 24px;
  color: #fe4729;
  font-weight: normal
}

.adminInfo span {
  display: inline-block;
  cursor: pointer;
  margin-left: 10px;
  float: left;
  height: 30px;
  color: #333;
  line-height:30px;
  border: 1px solid #fe4729;
  background-color: #ffff;
  border-radius: 4px;
  overflow: hidden;
  font-family: "Helvetica Neue", "Helvetica";
}

.adminInfo span.adminbg{
  border: 1px solid #fe4729;
  color: #fe4729;
  height: 30px;
}
.qiehuan{
  display: block;
  overflow: hidden;
  width: 630px;
  height: 210px;
  margin: 20px auto;
}
.qiehuan ol{
  float:left;
  width: 200px;
  height: 200px;
  display: block;
  text-align: center;
  font-weight: bold;
  border: 1px solid #eee;
  margin: 0 4px;
  cursor: pointer;
}
.qiehuan ol:hover{
  background-color: #f7f7f7;
}

.quanquan{
  width: 120px;
  height: 120px;
  border-radius: 120px;
  background-color: #07C160;
  color: #fff;
  display: block;
  line-height:120px;
  text-align:center;
  margin: 20px auto 20px;
  font-size: 60px;
}
.quanyellow{
  background-color: #f90;
}
.quanblue{
  background-color: #2aaefa;
}

</style>
