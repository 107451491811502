import request from '@/api/request.js'

// 全部团
export function getTuans (data) {
  return request({
    url: '/api/tuan/search',
    method: 'post',
    data
  })
}
// 开团大厅
export function dashboardTuan (data) {
  return request({
    url: '/api/tuan/dashboard',
    method: 'POST',
    data
  })
}
// 统计团的浏览数
export function getTuanNums (data) {
  return request({
    url: '/api/browsingrecords/add',
    method: 'POST',
    data
  })
}
// 团长的大厅
export function favoriteTuan (data) {
  return request({
    url: '/api/tuan/favorite',
    method: 'POST',
    data
  })
}
// 我分销的团
export function sellTuan (data) {
  return request({
    url: '/api/tuan/sell',
    method: 'POST',
    data
  })
}
// 上下架团
export function changeTuan (data) {
  return request({
    url: '/api/tuan/change',
    method: 'POST',
    data
  })
}
// 我发布的团
export function publishTuan (data) {
  return request({
    url: '/api/tuan/publish',
    method: 'POST',
    data
  })
}
// 团详情
export function detailTuan (data) {
  return request({
    url: '/api/tuan/detail',
    method: 'POST',
    data
  })
}
// 取消分销的团
export function cancelFavorite (data) {
  return request({
    url: '/api/tuan/cancelFavorite',
    method: 'POST',
    data
  })
}
// 查看团长分销的团
export function tuanzhangFavorite (data) {
  return request({
    url: '/api/tuan/listRetailerFavorite',
    method: 'POST',
    data
  })
}
// 开团
export function addTuan (data) {
  return request({
    url: '/api/tuan/add',
    method: 'POST',
    data
  })
}
// 编辑团
export function editTuan (data) {
  return request({
    url: '/api/tuan/edit',
    method: 'POST',
    data
  })
}
