import request from '@/api/request.js'

// 获取团长订单接口
export function getOrderList (data) {
  return request({
    url: '/api/order/search',
    method: 'POST',
    data
  })
}
// 获取团长订单接口
export function getOrderSupplier (data) {
  return request({
    url: '/api/order/search',
    method: 'POST',
    data
  })
}
// 取消订单
export function cancalOrder (data) {
  return request({
    url: '/api/order/batchCancelOrder',
    method: 'POST',
    data
  })
}

// 订单详情
export function getOrderDetail (data) {
  return request({
    url: '/api/order/detail',
    method: 'POST',
    data
  })
}
// 订单轨迹
export function getOperation (data) {
  return request({
    url: '/api/order/listOperation',
    method: 'POST',
    data
  })
}
// 修改金额
export function modityPrice (data) {
  return request({
    url: '/api/order/batchModifyPrice',
    method: 'POST',
    data
  })
}

// 批量线下结算
export function offlinePrice (data) {
  return request({
    url: '/api/order/batchOfflinePay',
    method: 'POST',
    data
  })
}

// 批量收款
export function batchReceipt (data) {
  return request({
    url: '/api/order/batchReceipt',
    method: 'POST',
    data
  })
}
// 批量付款
export function batchPay (data) {
  return request({
    url: '/api/order/batchPay',
    method: 'POST',
    data
  })
}

// 多次退款
export function batchRefund (data) {
  return request({
    url: '/api/order/multiRefund',
    method: 'POST',
    data
  })
}

// 供应商批量退款
export function batchRefundOrder (data) {
  return request({
    url: '/api/order/batchRefundOrder',
    method: 'POST',
    data
  })
}

// 批量转预售
export function yushouOrder (data) {
  return request({
    url: '/api/order/batchChangeDelivery',
    method: 'POST',
    data
  })
}

// 批量转待处理
export function transfer2process (data) {
  return request({
    url: '/api/order/batchBookToProcess',
    method: 'POST',
    data
  })
}

// 批量审核订单
export function confirmOrder (data) {
  return request({
    url: '/api/order/batchConfirmOrder',
    method: 'POST',
    data
  })
}
// 批量作废订单
export function nullOrder (data) {
  return request({
    url: '/api/order/batchInvalidOrder',
    method: 'POST',
    data
  })
}
// 批量还原订单
export function batchRestoreOrder (data) {
  return request({
    url: '/api/order/batchRestoreOrder',
    method: 'POST',
    data
  })
}
// 批量置为已发货
export function alreadySendOrder (data) {
  return request({
    url: '/api/order/batchMarkAlreadySend',
    method: 'POST',
    data
  })
}
// 批量同意退单
export function agreeCancelOrder (data) {
  return request({
    url: '/api/order/batchAgreeCancelOrder',
    method: 'POST',
    data
  })
}
// 批量申请退单
export function applyCancelOrder (data) {
  return request({
    url: '/api/order/batchApplyCancelOrder',
    method: 'POST',
    data
  })
}
// 批量拒绝退单
export function rejectOrder (data) {
  return request({
    url: '/api/order/batchRejectCancelOrder',
    method: 'POST',
    data
  })
}
// 导入订单
export function importOrder (data) {
  return request({
    url: '/api/order/import',
    method: 'POST',
    data
  })
}
// 导出查询订单
export function exportOrder (data) {
  return request({
    url: '/api/order/export',
    method: 'POST',
    data
  })
}
// 导出选择订单
export function selectOrder (data) {
  return request({
    url: '/api/order/exportBySelect',
    method: 'POST',
    data
  })
}
// 上传快递单号
export function upDelivery (data) {
  return request({
    url: '/api/order/directUploadDelivery',
    method: 'POST',
    data
  })
}
// 导入快递单号
export function importDelivery (data) {
  return request({
    url: '/api/order/delivery/import',
    method: 'POST',
    data
  })
}
// 修改收货人信息
export function batchModifyConsignee (data) {
  return request({
    url: '/api/order/batchModifyConsignee',
    method: 'POST',
    data
  })
}
// 修改供应商备注
export function supplierBatchModifyRemark (data) {
  return request({
    url: '/api/order/supplierBatchModifyRemark',
    method: 'POST',
    data
  })
}
// 修改团长备注
export function retailerBatchModifyRemark (data) {
  return request({
    url: '/api/order/retailerBatchModifyRemark',
    method: 'POST',
    data
  })
}
// 批量添加【我买的订单】
export function batchAdd (data) {
  return request({
    url: '/api/order/batchAdd',
    method: 'POST',
    data
  })
}

// 供应商【匹配商品】
export function matchSku (data) {
  return request({
    url: '/api/order/matchSku',
    method: 'POST',
    data
  })
}

// 团长【匹配供应商】
export function matchSupplier (data) {
  return request({
    url: '/api/order/matchSupplier',
    method: 'POST',
    data
  })
}

// 团长 撤销交单
export function revoke (data) {
  return request({
    url: '/api/order/revoke',
    method: 'POST',
    data
  })
}

//【团长】推单
export function sendSupplier (data) {
  return request({
    url: '/api/order/sendSupplier',
    method: 'POST',
    data
  })
}


export function retailerMatchSku (data) {
  return request({
    url: '/api/order/retailerMatchSku',
    method: 'POST',
    data
  })
}


// 导入供应商订单
export function supplierBatchAdd (data) {
  return request({
    url: '/api/order/supplierBatchAdd',
    method: 'POST',
    data
  })
}

// 修改推送状态
export function changeSendStatus (data) {
  return request({
    url: '/api/order/changeSendToGroupStatus',
    method: 'POST',
    data
  })
}

// 退款详情
export function getRefundHistory (data) {
  return request({
    url: '/api/refundOrder/history',
    method: 'POST',
    data
  })
}

// 标记线下退款
export function offlineRefund (data) {
  return request({
    url: '/api/order/offlineRefund',
    method: 'POST',
    data
  })
}

// 无需物流的订单标记已完成
export function markCompletedWithoutDelivery(data) {
  return request({
    url: '/api/order/markCompletedWithoutDelivery',
    method: 'POST',
    data
  })
}

// 团长极速交单
export function speedSendSupplier(data) {
  return request({
    url: '/api/order/speedSendSupplier',
    method: 'POST',
    data
  })
}